/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-props-destruction */
import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import Slider from 'react-slick';

import './SlickSlider.style';

/** @namespace myApp/Component/SlickSlider/Component */
export class SlickSliderComponent extends PureComponent {
    static propTypes = {
        children: PropTypes.element,
        slidesToShow: PropTypes.number,
        slidesToScroll: PropTypes.number,
        isInfinite: PropTypes.bool,
        isDots: PropTypes.bool,
        isArrows: PropTypes.bool,
        isCenterMode: PropTypes.bool,
        centerPadding: PropTypes.string,
        isAutoplay: PropTypes.bool,
        autoplaySpeed: PropTypes.number,
        isFade: PropTypes.bool
    };

    static defaultProps = {
        children: null,
        slidesToShow: 3,
        slidesToScroll: 3,
        isInfinite: true,
        isDots: true,
        isArrows: false,
        isCenterMode: false,
        centerPadding: null,
        isAutoplay: false,
        autoplaySpeed: 4000,
        isFade: false
    };

    componentProps = () => {
        const {
            slidesToShow,
            slidesToScroll,
            isInfinite,
            isDots,
            isCenterMode,
            centerPadding,
            isAutoplay,
            autoplaySpeed,
            isFade,
            isArrows
        } = this.props;

        return {
            dots: isDots,
            infinite: isInfinite,
            arrows: isArrows,
            speed: 500,
            slidesToShow,
            slidesToScroll,
            centerMode: isCenterMode,
            centerPadding,
            autoplay: isAutoplay,
            autoplaySpeed,
            fade: isFade
        };
    };

    render() {
        const { children } = this.props;

        return (
            <div block="SlickSlider">
                <Slider { ...this.componentProps() }>{ children }</Slider>
            </div>
        );
    }
}

export default SlickSliderComponent;
