/* eslint-disable radix */
import ThankyouPageOrderQuery from 'Query/ThankyouPageOrder.query';
import { fetchQuery } from 'Util/Request';

import { CHECKOUT_COMPLETED } from '../config';
import {
    getCartProductsColorsString,
    getCartProductsImagesArray,
    getCartProductsNameString,
    getCartProductsQtysString, getCartProductsSizesString, getCartProductsSkuString, getProductsGenderString
} from '../helper/Checkout';
import { eventTrack } from '../helper/WebEngage/Event';

const productDetails = (items) => items.map((product) => ({
    Quantity: product.qty_ordered,
    Color: product.color,
    Size: product.size,
    'Product Name': product.title,
    'Product Image': product.product_image,
    'Product SKU': product.sku
}));

export const getThankyouPagheOrder = (args, callback, instance) => {
    const { orderID } = instance.props;
    fetchQuery(ThankyouPageOrderQuery.getSalesOrderQuery(orderID))
        .then(
            /** @namespace myApp/Component/CheckoutSuccess/Container/CheckoutSuccessContainer/getThankyouPagheOrder/fetchQuery/then */
            (data) => {
                instance.setState({ orderDetail: data });

                const {
                    salesOrder: {
                        items = [], increment_id, shippingamount, grand_total, payment_method
                    }
                } = data;

                const grandTotal = grand_total.replace(/[₹,]/g, '');
                const shippingAmount = shippingamount.replace(/[₹,]/g, '');

                eventTrack(CHECKOUT_COMPLETED, {
                    'Order ID': increment_id,
                    'Product Name': getCartProductsNameString(items),
                    'Product SKU': getCartProductsSkuString(items),
                    'Product Image': getCartProductsImagesArray(items),
                    Color: getCartProductsColorsString(items),
                    Size: getCartProductsSizesString(items),
                    Quantity: getCartProductsQtysString(items),
                    'Product Details': productDetails(items),
                    'Payment Mode': payment_method,
                    Gender: getProductsGenderString(items),
                    'Estimated Shipping': parseInt(shippingAmount),
                    'Sub Total': parseInt(grandTotal)
                });
            }
        );
};

export default {
    'myApp/Component/CheckoutSuccess/Container': {
        'member-function': {
            getThankyouPagheOrder
        }
    }
};
