import OrderQuery from 'Query/Order.query';
import {
    OrderDispatcher as SourceOrderDispatcher
} from 'SourceStore/Order/Order.dispatcher';
import { showNotification } from 'Store/Notification/Notification.action';
import { formatOrders } from 'Util/Orders';
import { fetchQuery, getErrorMessage } from 'Util/Request';

/** @namespace myApp/Store/Order/Dispatcher */
export class OrderDispatcher extends SourceOrderDispatcher {
    async getOrderById(dispatch, orderId) {
        try {
            const {
                customer: {
                    orders: {
                        items
                    }
                }
            } = await fetchQuery(OrderQuery.getOrderListQuery({ orderId }));
            const formattedOrders = formatOrders(items);

            return formattedOrders[0];
        } catch (error) {
            dispatch(showNotification('error', getErrorMessage(error)));

            return null;
        }
    }
}

export default new OrderDispatcher();
